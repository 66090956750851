<template>
  <div class="dev-group">
    <h2 class="group-title">Variables</h2>
    <p>Access Variables by calling <code>this.$root.variableName</code></p>
    <pre>{{ rootVariables }}</pre>
  </div>
</template>

<script>
export default {
  name: 'DevRootVariables',
  data () {
    return {
      rootVariables: this.$root.$data
    }
  }
}
</script>
